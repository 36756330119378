/* eslint-disable @typescript-eslint/no-empty-function */

// dummy handler to fix issue with zone.js
// ? https://github.com/angular/components/issues/24979#issuecomment-1717099245
document.addEventListener('mousemove', () => {}, { passive: false, capture: true });

// Disable mounseenter event on iOS. Fixes issue where buttons sometimes need to be clicked twice.
// Platform hecks do exist at e.g. https://github.com/angular/components/blob/main/src/material/tooltip/tooltip.ts#L849 but they are incorrect.
// We prefer to just disable the mouseenter event alltogether to catch all cases because mouse enter events are meaningless on mobile.

const isIos = /iPad|iPhone|iPod/.test(navigator.userAgent);

const ogAddEventListener = Element.prototype.addEventListener;
Element.prototype.addEventListener = function <K extends keyof ElementEventMap>(
  type: K | string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  listener: ((this: Element, ev: ElementEventMap[K]) => any) | EventListenerOrEventListenerObject,
  options?: boolean | AddEventListenerOptions | undefined,
) {
  if (type === 'mouseenter' && isIos) return;
  return ogAddEventListener.bind(this)(type, listener, options);
};
